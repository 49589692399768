import axios, { AxiosError } from "axios";
import {
  AccountInfo,
  DropdownItem,
  LuceneSearchRequest,
  SongInfo,
  SongSearchForm,
  SongSearchResponse,
  CreateAccountRequest,
  VerifyResponse,
  CartInfo,
  CartsResponse,
  UserResult,
  ShortAccountInfo,
  TargetExportTargetItem,
  TargetExportSongInfo,
  PublicCartResponse,
  TargetExportRequest,
  Themecart,
  ThemecartsAdminInfo,
  ThemecartEditRequest,
  ThemecartCreateRequest,
  MaintainancePeriod,
  MaintainancePeriodEdit,
  UserSearchInfo,
  QueryResults,
  GeneralStatistic,
  SongStatistics,
  CartStatistics,
  UserStatistics,
  CredentialCookie,
  DropdownItemsTable,
  DropdownItemEditRequest,
  DropdownStatistics,
  KokoIdRange,
  ViewStatistics,
  AppSupportMessage,
  AppSupportMessageTitle,
  EditAppSupportMessageTitle,
  EditAppSupportMessage,
  ViewStatistic,
  SimilarMatch,
  NameCountStatistics,
  SynonymResults,
  IdName,
  Synonym,
} from "@/util/classes";
import SessionStorage from "./sessionstorage";
import { StatusCodes } from "http-status-codes";
import Funcs from "./helperFunc";
import i18n from "@/plugins/i18n";
import { apiRouteNames } from "./constants";
import Cookies from "js-cookie";
import { CREDENTIAL_COOKIE_IDENTIFIER_KEY } from "@/util/constants";

// This function logs the user in and returns a HTTP Statuscode and an optional error message.
async function login(
  name: string,
  password: string
): Promise<[StatusCodes, string]> {
  if (name === "" || password === "") {
    throw new Error("Name and password can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.identity}/login`,
      { email: name, password: password },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      Cookies.set(
        CREDENTIAL_COOKIE_IDENTIFIER_KEY,
        JSON.stringify(new CredentialCookie(resp.data.isMVuser, name)), {
        sameSite: "Strict"
      }
      );
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [
          StatusCodes.UNAUTHORIZED,
          axiosError.response.data as string,
        ];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.BAD_REQUEST,
          axiosError.response.data as string,
        ];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This function logs the user out and returns a status code.
async function logout(): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.identity}/logout`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      //SessionStorage.clear();
      Cookies.remove(CREDENTIAL_COOKIE_IDENTIFIER_KEY);
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    SessionStorage.removeItem("user");
    SessionStorage.removeItem("isMvUser");
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This function is used by normal users to search for songs.
// Use this to search for songs, pagination and cartchange
async function songSearch(
  form: SongSearchForm
): Promise<SongSearchResponse | null> {
  try {
    const resp = await axios.post<SongSearchResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/search`,
      JSON.stringify(form),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    if (axiosError.message.includes("Request aborted")) {
      return new SongSearchResponse([], 0);
    }

    return null;
  }
}

// This function is used by mv users to search for songs.
// Use this to search for songs, pagination and cartchange
async function directLuceneSearch(
  req: LuceneSearchRequest
): Promise<[StatusCodes, SongSearchResponse | string]> {
  try {
    const resp = await axios.post<SongSearchResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/lucenesearch`,
      JSON.stringify(req),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.BAD_REQUEST,
          (axiosError.response.data as any).errorMessage,
        ];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This function gets the list items for the dropdowns in the search form.
// The name parameter is the topic of the dropdown. (emotion, instrument)
async function getDropDownItems(
  name: string,
  langType: number
): Promise<DropdownItem[] | null> {
  if (name === "") {
    throw new Error("Dropdown name can't be null.");
  }

  try {
    const resp = await axios.get<DropdownItem[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.song
      }/getdropdown/${encodeURIComponent(name)}/${langType}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const arr: DropdownItem[] = [];
      resp.data.forEach(async (element: any) => {
        arr.push(await Funcs.mapJsonToDropDown(JSON.stringify(element)));
      });

      return arr;
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    return null;
  }
}

// This function gets all information about the song with the given number.
async function getSongDetailed(id: number): Promise<SongInfo | null> {
  if (id < 0) {
    throw new Error("SongId cant be smaller than null.");
  }

  try {
    const resp = await axios.get<SongInfo>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/searchdetailed/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    return null;
  }
}

// This function registers a new user at the api.
async function register(req: CreateAccountRequest): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.identity}/register`,
      JSON.stringify(req),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.OK;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This function is used to get a link that can reset the password.
// The link is sent via mail.
// It can return an optional error message.
async function createPasswordResetLink(
  email: string
): Promise<[StatusCodes, string]> {
  if (email === "") {
    throw new Error("Email can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.identity}/resetpassword`,
      JSON.stringify({ email: email }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.OK, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.BAD_REQUEST,
          (axiosError.response.data as any).resKey,
        ];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method is used to reset the password of the user.
async function resetPassword(
  password: string,
  userId: number,
  guid: string,
  isoldpasswordreset: boolean
): Promise<[StatusCodes, string]> {
  if (!Funcs.isGuid(guid)) {
    throw new Error("Function needs a guid!");
  }

  if (password === "") {
    throw new Error("Password can't be empty.");
  }

  if (userId < 0) {
    throw new Error("UserId can't be smaller than 0.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.identity}/resetpassword/${userId}/${guid}?oldPasswordReset=${isoldpasswordreset}`,
      JSON.stringify({ password: password }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.OK, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.BAD_REQUEST,
          axiosError.response.data as string,
        ];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method updates the users email.
async function updateEmail(newEmail: string): Promise<[StatusCodes, string]> {
  if (newEmail === "") {
    throw new Error("Email can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/editemail`,
      JSON.stringify({ email: newEmail }),
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ''];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ''];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
    }
  }
}

// This method gets the account information about the user.
async function getAccountInfo(): Promise<AccountInfo | null> {
  try {
    const resp = await axios.get<AccountInfo>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/edit`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return null;
      case StatusCodes.BAD_REQUEST.valueOf():
        return null;
      default:
        return null;
    }
  }
}

// This method updates the information about the user.
async function updateAccount(info: AccountInfo): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/editpersondata`,
      info,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.OK;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method is used to verify the email address of the user.
// This is needed when a new user verifies his email or
// If a user changed his email.
async function verifyEmail(
  userId: number,
  verificationId: string
): Promise<[StatusCodes, VerifyResponse]> {
  if (!Funcs.isGuid(verificationId)) {
    throw new Error("Guid expected");
  }

  if (userId < 0) {
    throw new Error("UserId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/emailverify/${userId}/${verificationId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as VerifyResponse];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, new VerifyResponse("", "")];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, new VerifyResponse("", "")];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.BAD_REQUEST,
          new VerifyResponse("", "verification"),
        ];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, new VerifyResponse("", "")];
    }
  }
}

// This method is used to verify new users for the web app.
async function verifyUser(
  userId: number,
  verificationId: string
): Promise<[StatusCodes, string]> {
  if (!Funcs.isGuid(verificationId)) {
    throw new Error("Guid expected");
  }

  if (userId < 0) {
    throw new Error("UserId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/verify/${userId}/${verificationId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data.email];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, ""];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method gets a given amount of carts.
async function getCarts(
  currentPage: number,
  entries: number,
  sortType: number,
  ascending: boolean,
  query: string
): Promise<[StatusCodes, CartsResponse | null]> {
  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  if (entries < 0) {
    throw new Error("Entries can't be smaller than 0.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.shoppingcart
      }/management/${sortType}/${ascending}?query=${encodeURIComponent(query)}`,
      {
        currentPage: currentPage,
        entries: entries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const carts: CartInfo[] = [];
      resp.data.carts.forEach((element: any) => {
        carts.push(Funcs.mapJsonToCartInfo(JSON.stringify(element)));
      });
      return [StatusCodes.OK, new CartsResponse(carts, resp.data.totalResults)];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// THis method deletes the cart with the given number.
async function deleteCart(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/delete/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method copies the cart with the given id.
async function copyCart(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/copy/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method deletes the carts with the given ids.
async function deleteCarts(cartIds: number[]): Promise<StatusCodes> {
  if (cartIds.length == 0) {
    throw new Error("Carts can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/delete`,
      cartIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method updates the title and description of the cart with the given id.
async function updateCart(
  id: number,
  name: string,
  desc: string
): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("CartId can't be smaller than 1.");
  }

  if (name === "") {
    throw new Error("Name can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/edit`,
      { shoppingCartId: id, name: name, description: desc },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets a given amount of songs that are contained in the cart.
async function getCartSongs(
  id: number,
  currentPage: number,
  numberOfEntries: number
): Promise<[StatusCodes, SongSearchResponse]> {
  if (numberOfEntries < 0) {
    throw new Error("CartId and Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  try {
    const queryId = id === -1 ? "" : `?id=${id}`;
    const resp = await axios.post<SongSearchResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/index${queryId}`,
      { currentPage: currentPage, entries: numberOfEntries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      resp.data.searchResult.forEach((element: SongInfo) => {
        element.isInCart = true;
      });
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, new SongSearchResponse([], 0)];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, new SongSearchResponse([], 0)];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [
          StatusCodes.INTERNAL_SERVER_ERROR,
          new SongSearchResponse([], 0),
        ];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, new SongSearchResponse([], 0)];
      default:
        return [
          StatusCodes.INTERNAL_SERVER_ERROR,
          new SongSearchResponse([], 0),
        ];
    }
  }
}

// This method gets information about all carts.
// THis is used to populate the dropdown where you can choose your active cart.
async function getCartsInfo(): Promise<CartInfo[] | null> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/all`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const carts: CartInfo[] = [];

      try {
        resp.data.forEach((element: any) => {
          carts.push(Funcs.mapJsonToCartInfo(JSON.stringify(element)));
        });

        return carts;
      } catch (error) {
        return [];
      }
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return null;
      case StatusCodes.BAD_REQUEST.valueOf():
        return null;
      default:
        return null;
    }
  }
}

// THis method creates a new cart.
async function createCart(
  title: string,
  description: string
): Promise<StatusCodes> {
  if (title === "") {
    throw new Error("Title can't be empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/add`,
      { name: title, description: description },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method loads the cart with the given id.
// This is used when changing the cart via dropdown, so it shows up on the cart list at the top.
async function loadCart(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/load/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method adds the songs with the given ids to the cart.
async function addSongsToCart(
  cartId: number,
  songIds: number[]
): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  if (songIds.length == 0) {
    throw new Error("Songlist is empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/add/${cartId}/song`,
      songIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method adds the song with the id to the given cart.
async function addSongToCart(
  cartId: number,
  songId: number
): Promise<StatusCodes> {
  if (cartId < 0 || songId < 0) {
    throw new Error("CartId and Song can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/add/${cartId}/song/${songId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method deletes the songs with the given ids to the cart.
async function deleteSongsInCart(
  cartId: number,
  songIds: number[]
): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  if (songIds.length == 0) {
    throw new Error("Songlist is empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/delete/${cartId}/song`,
      songIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method deletes the song with the id from the given cart.
async function deleteSongInCart(
  cartId: number,
  songId: number
): Promise<StatusCodes> {
  if (cartId < 0 || songId < 0) {
    throw new Error("CartId and Song can't be smaller than 0.");
  }

  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/delete/${cartId}/song/${songId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets information about the cart with the given id.
// async function getCart(cartId: number): Promise<[StatusCodes, CartInfo | null]>{
//     try {
//         const resp = await axios.get(`${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/${cartId}`, {
//             headers:{
//                 "Content-Type":"application/json"
//             },
//             withCredentials: true
//         });

//         if (resp.status === 200) {
//             const cart: CartInfo =  Funcs.mapJsonToCartInfo(JSON.stringify(resp.data))
//             return [StatusCodes.OK, cart];
//         }

//         return [StatusCodes.INTERNAL_SERVER_ERROR, null];
//     } catch (error: any) {
//         const axiosError = error as AxiosError;
//         switch (axiosError.response?.status) {
//             case StatusCodes.UNAUTHORIZED.valueOf():
//                 return [StatusCodes.UNAUTHORIZED, null];
//             case StatusCodes.BAD_REQUEST.valueOf():
//                 return [StatusCodes.BAD_REQUEST, null];
//             case StatusCodes.NOT_FOUND.valueOf():
//                 return [StatusCodes.NOT_FOUND, null];
//             default:
//                 return [StatusCodes.INTERNAL_SERVER_ERROR, null];
//         }
//     }
// }

// This method downloads the songs with the ids as a zip in mp3 format.
// Used by the multiple selection download.
async function downloadAllSongsAsMP3(songIds: number[]): Promise<StatusCodes> {
  if (songIds.length == 0) {
    throw new Error("Songlist is empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/downloadmp3`,
      songIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        responseType: "arraybuffer",
      }
    );

    if (resp.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = resp.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
        .replaceAll('"', "");
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method downloads the songs with the ids as a zip in wav format.
// Used by the multiple selection download.
async function downloadAllSongsAsWAV(songIds: number[]): Promise<StatusCodes> {
  if (songIds.length == 0) {
    throw new Error("Songlist is empty.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/downloadwav`,
      songIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        responseType: "blob",
      }
    );

    if (resp.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = resp.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
        .replaceAll('"', "");
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets the akm file download link, creates a link and then downloads the file.
// The parameter represents the id of the cart, from which you want to create the akm file.
async function downloadAkmFile(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/akmexport/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      // This request just returns the link to the download.
      // Thats why we need to create a new element and click it via js.
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method downloads all songs in the cart with the given id as a zip in mp3 format.
async function downloadMp3Zip(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/downloadmp3/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = new Blob([resp.data], { type: "application/zip" });
      const link = document.createElement("a");
      const objectUrl: string = URL.createObjectURL(url);
      link.href = objectUrl;
      link.setAttribute("download", resp.headers["filename"]);
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method downloads all songs in the cart with the given id as a zip in wav format.
async function downloadWavZip(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/downloadwav/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = new Blob([resp.data], { type: "application/zip" });
      const link = document.createElement("a");
      const objectUrl: string = URL.createObjectURL(url);
      link.href = objectUrl;
      link.setAttribute("download", resp.headers["filename"]);
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method returns a public link to the cart with the given id.
async function getPublicLink(cartId: number): Promise<[StatusCodes, string]> {
  if (cartId < 0) {
    throw new Error("CartId cant be smaller than null.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/publiclink/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data.publicLink];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method searches for songs in the cart with the given id.
// This is just a simple text search that compares the query with the album, title and artists.
async function searchSongInCart(
  cartId: number,
  query: string,
  currentPage: number,
  numberOfEntries: number
): Promise<[StatusCodes, SongSearchResponse | null]> {
  if (cartId < 0 || numberOfEntries < 0) {
    throw new Error("CartId and entries can't be smaller than 0.");
  }

  if (query === "") {
    throw new Error("Query can't be empty.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  try {
    const resp = await axios.post<SongSearchResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.shoppingcart
      }/search/${cartId}?query=${encodeURIComponent(query)}`,
      {
        currentPage: currentPage,
        entries: numberOfEntries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      
      resp.data.searchResult.forEach((element: SongInfo) => {
        element.isInCart = true;
      });
      return [
        StatusCodes.OK,
        resp.data,
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method searches for a user.
// This is used when sharing a cart with another registered user.
async function searchUser(
  query: string
): Promise<[StatusCodes, UserResult[] | null]> {
  if (query === "") {
    throw new Error("Query can't be empty.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.account
      }/search?query=${encodeURIComponent(query)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const users: UserResult[] = Funcs.mapJsonToUserResults(
        JSON.stringify(resp.data)
      );
      return [StatusCodes.OK, users];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method is used to send the cart with the given id to a list of users with the given ids.
async function shareCartWithUsers(
  userIds: number[],
  cartId: number
): Promise<StatusCodes> {
  if (userIds.length < 1) {
    throw new Error("CartId and entries can't be smaller than 0.");
  }

  if (cartId < 0) {
    throw new Error("CartId can't be smaller than 0.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/share/${cartId}`,
      userIds,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets account information for the target export modal.
// Caution! This method doesn't return all account informations only email and user name.
async function getShortAccountInfo(): Promise<
  [StatusCodes, ShortAccountInfo | null]
> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/shortinfo`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        Funcs.mapJsonToShortAccountInfo(JSON.stringify(resp.data)),
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets all possible targets for the target export.
async function getExportTargets(): Promise<TargetExportTargetItem[] | null> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/targetexport`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return Funcs.mapJsonToTargetExportTargetItems(JSON.stringify(resp.data));
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return null;
      case StatusCodes.BAD_REQUEST.valueOf():
        return null;
      default:
        return null;
    }
  }
}

// This method exports the given song to the target.
async function exportTargetSong(
  request: TargetExportRequest
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/targetexport`,
      request,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets information about the songs that are currently exported.
async function getTargetExportSongs(
  cartId: number
): Promise<[StatusCodes, TargetExportSongInfo[] | null]> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/targetexport/cart/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        await Funcs.mapJsonToTargetExportSongs(JSON.stringify(resp.data)),
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets all songs from a publicly available cart.
async function getPublicSongs(
  guid: string,
  currentPage: number,
  numberOfEntries: number,
  cartid: number
): Promise<[StatusCodes, PublicCartResponse | null]> {
  if (numberOfEntries < 0) {
    throw new Error("CartId and Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  if (!Funcs.isGuid(guid)) {
    throw new Error("No Guid found!");
  }

  try {
    const resp = await axios.post<PublicCartResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/public/${guid}?cartid=${cartid}`,
      { currentPage: currentPage, entries: numberOfEntries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        resp.data,
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method searches for a song in the public cart.
// This is just a simple text search that compares the query with the album, title and artists.
async function searchPublicSong(
  guid: string,
  query: string,
  currentPage: number,
  numberOfEntries: number,
  cartid: number
): Promise<[StatusCodes, PublicCartResponse | null]> {
  if (numberOfEntries < 0) {
    throw new Error("CartId and Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  if (!Funcs.isGuid(guid)) {
    throw new Error("No Guid found!");
  }

  if (query === "") {
    throw new Error("Query can't be empty!");
  }

  try {
    const resp = await axios.post<PublicCartResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.shoppingcart
      }/public/${guid}/search?query=${encodeURIComponent(
        query
      )}&cartid=${cartid}`,
      { currentPage: currentPage, entries: numberOfEntries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        resp.data,
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets a given amount of sorted songs from a cart.
// All sorting types except default use this method.
async function getSortedSongs(
  cartId: number,
  query: string,
  sortType: number,
  ascending: boolean,
  currentPage: number,
  numberOfEntries: number
): Promise<[StatusCodes, SongSearchResponse | null]> {
  if (numberOfEntries < 0) {
    throw new Error("CartId and Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  if (cartId < 1) {
    throw new Error("Cart Id can't be smaller than 1.");
  }

  if (sortType < 1) {
    throw new Error("SortType Id can't be smaller than 1.");
  }

  try {
    const resp = await axios.post<SongSearchResponse>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.shoppingcart
      }/sorted/${cartId}/${sortType}/${ascending}?query=${encodeURIComponent(
        query
      )}`,
      { currentPage: currentPage, entries: numberOfEntries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      resp.data.searchResult.forEach((element: SongInfo) => {
        element.isInCart = true;
      });
      return [
        StatusCodes.OK,
        resp.data,
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method switches the position of two songs in a cart.
// This is only used when sorting tracks in a cart after the type custom.
async function switchTrackItemsOrder(
  cartId: number,
  songIdA: number,
  songIdB: number
): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("Cart Id can't be smaller than 1.");
  }

  if (songIdA == songIdB) {
    throw new Error("You can't switch a song with the same id.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/${cartId}/switch/${songIdA}/${songIdB}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method pushes the song to the given position in a cart.
// This is only used when sorting tracks in a cart after the type custom.
async function moveTrackItemToPos(
  cartId: number,
  songId: number,
  pos: number
): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("Cart Id can't be smaller than 1.");
  }

  if (songId < 0) {
    throw new Error("Song Id can't be smaller than 0.");
  }

  if (pos < 0) {
    throw new Error("New position can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/${cartId}/move/${songId}/${pos}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets all theme collections used in the news popup.
async function getLatestCollections(): Promise<Themecart[] | null> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/latest`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return resp.data as Themecart[];
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.BAD_REQUEST.valueOf():
        return null;
      default:
        return null;
    }
  }
}

// This method gets the themecarts for the admin view.
async function getThemeCarts(
  currentPage: number,
  entries: number
): Promise<[StatusCodes, ThemecartsAdminInfo | null]> {
  if (entries < 0) {
    throw new Error("Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/get`,
      { currentPage: currentPage, entries: entries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ThemecartsAdminInfo];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method searches the themecarts for the admin view.
async function searchThemeCarts(
  currentPage: number,
  entries: number,
  query: string
): Promise<[StatusCodes, ThemecartsAdminInfo | null]> {
  if (entries < 0) {
    throw new Error("Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  if (query == "") {
    throw new Error("Query cant be emtpy.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${
        apiRouteNames.news
      }/get?query=${encodeURIComponent(query)}`,
      { currentPage: currentPage, entries: entries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ThemecartsAdminInfo];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method updates a specific theme cart.
async function editThemeCart(
  request: ThemecartEditRequest
): Promise<StatusCodes> {
  if (request == null) {
    throw new Error("Request cant be null.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/edit/${request.id}`,
      {
        title: request.title,
        description: request.description,
        coverFileName: request.coverFileName,
        cartId: request.cartId,
        showThemeCart: request.showThemeCart,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method deletes a specific theme cart.
async function deleteThemeCart(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method clones a theme collection with the given id.
async function cloneThemeCart(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/clone/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method deletes specific theme carts.
async function deleteThemeCarts(ids: number[]): Promise<StatusCodes> {
  if (ids.some((el) => el < 0)) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/delete`,
      ids,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets the names of the covers for the theme carts.
async function getThemecartsCoverFileNames(): Promise<[StatusCodes, string[]]> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/theme/names`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, []];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, []];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, []];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, []];
    }
  }
}

// This method creates a new themecart.
async function createThemecart(
  request: ThemecartCreateRequest
): Promise<StatusCodes> {
  if (request == null) {
    throw new Error("Request cant be null.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.news}/add`,
      {
        title: request.title,
        description: request.description,
        coverFileName: request.coverFileName,
        cartId: request.cartId,
        showThemeCart: request.showThemeCart,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method uploads a new coverfile for a theme cart on the server.
async function uploadCollectionCoverFile(file: File): Promise<[StatusCodes, string]> {
  if (file == null) {
    throw new Error("File can't be empty!");
  }

  const formData = new FormData();

  formData.append("file", file);

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/theme/upload`,
      formData,
      {
        headers: {
          "Content-Type": "image/png",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ''];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ''];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
      case StatusCodes.CONFLICT.valueOf():
        return [StatusCodes.CONFLICT, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
    }
  }
}

// This method exports a cart into an excel spreadsheet.
async function exportCartAsExcel(cartId: number): Promise<StatusCodes> {
  if (cartId < 0) {
    throw new Error("Cartid cant be smaller than 0!");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.shoppingcart}/songexport/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports an array of kokoids into an excel spreadsheet.
async function exportKokoIDsAsExcel(kokoId: KokoIdRange): Promise<[StatusCodes, string]> {
  if (kokoId == null) {
    throw new Error("IDs can't be empty!");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/songexport`,
      kokoId,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return [StatusCodes.OK, ''];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ''];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
    }
  }
}

// This method gets all maintainance periods in the database.
async function getMaintainanceInfo(): Promise<MaintainancePeriod[] | null> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/get`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return Funcs.mapJsonToMaintainancePeriods(JSON.stringify(resp.data));
    }

    return null;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return null;
      case StatusCodes.BAD_REQUEST.valueOf():
        return null;
      default:
        return null;
    }
  }
}

// This method gets the active maintainance periods.
async function getAllMaintainanceInfo(): Promise<
  [StatusCodes, MaintainancePeriodEdit[] | null]
> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/all`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        Funcs.mapJsonToMaintainancePeriodEdits(JSON.stringify(resp.data)),
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method updates the given maintainance period.
async function updateMaintainanceInfo(
  request: MaintainancePeriodEdit
): Promise<StatusCodes> {
  if (request == null) {
    throw new Error("Request cant be null.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/update/${request.id}`,
      {
        description: request.description,
        startDateUTC: Math.floor(request.startDate.getTime() / 1000),
        endDateUTC: Math.floor(request.endDate.getTime() / 1000),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets sorted user from the db.
// This is used for the manage user table.
async function getSortedUsers(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean
): Promise<[StatusCodes, UserSearchInfo | null]> {
  if (entries < 0) {
    throw new Error("Entries can't be smaller than 0.");
  }

  if (currentPage < 1) {
    throw new Error("Page can't be smaller than 1.");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/get/sorted/${sortType}/${ascending}?query=${query}`,
      { currentPage: currentPage, entries: entries },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const users = Funcs.mapJsonToUsersInfo(JSON.stringify(resp.data.users));
      return [StatusCodes.OK, new UserSearchInfo(users, resp.data.results)];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method is used to block a user from using the app.
async function blockUser(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/block/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method is used to delete a user from the app.
async function deleteUser(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method is used to unblock a blocked user.
async function unblockUser(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/unblock/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method is used to verify a unverified user.
// Used by the manage user table.
async function verifyUserAsAdmin(id: number): Promise<StatusCodes> {
  if (id < 0) {
    throw new Error("Id can't be smaller than 0.");
  }

  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.account}/verify/admin/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.INTERNAL_SERVER_ERROR;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

async function getViewsPerMonth(
  sortAfterDate: boolean,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, ViewStatistic[] | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/visit/monthly/${sortAfterDate}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ViewStatistic[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

async function getNewUsersPerMonth(
  sortAfterDate: boolean,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, ViewStatistic[] | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/users/monthly/${sortAfterDate}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ViewStatistic[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

async function getQueriesPerMonth(
  sortAfterDate: boolean,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, ViewStatistic[] | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/queryresults/monthly/${sortAfterDate}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ViewStatistic[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

async function getDropdownsPerMonth(
  sortAfterDate: boolean,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, ViewStatistic[] | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/dropdowns/monthly/${sortAfterDate}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as ViewStatistic[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

async function getGenreStatistics(sortByCount: boolean, ascending: boolean, currentPage: number, entries: number): Promise<[StatusCodes, NameCountStatistics | null]>{
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/genre/${sortByCount == true ? 1 : 2}/${ascending}`,
      {
        currentPage: currentPage,
        entries: entries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as NameCountStatistics];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

async function getKeywordStatistics(sortByCount: boolean, ascending: boolean, currentPage: number, entries: number): Promise<[StatusCodes, NameCountStatistics | null]>{
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/keyword/${sortByCount == true ? 1 : 2}/${ascending}`,
      {
        currentPage: currentPage,
        entries: entries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as NameCountStatistics];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the searchquery statistics.
async function getQueryStatistics(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, QueryResults | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/queryresults/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as QueryResults];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the song statistics.
async function getSongStatistics(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, SongStatistics | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/songs/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as SongStatistics];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the cart statistics.
async function getCartStatistics(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, CartStatistics | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/carts/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as CartStatistics];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the user statistics.
async function getUserStatistics(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, UserStatistics | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/users/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        Funcs.mapJsonToUserStatistics(JSON.stringify(resp.data)),
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets general statistics about the app.
async function getGeneralStatistics(): Promise<
  [StatusCodes, GeneralStatistic | null]
> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/generalstats`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as GeneralStatistic];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the dropdown item statistics.
async function getDropdownStatistics(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number,
  language: number
): Promise<[StatusCodes, DropdownStatistics | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/dropdowns/${language}/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as DropdownStatistics];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method gets the dropdown items for the dropdown item table.
async function getDropdownItemsForTable(
  currentPage: number,
  entries: number,
  query: string,
  sortType: number,
  ascending: boolean,
  lang: number
): Promise<[StatusCodes, DropdownItemsTable | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/get/dropdownitem/${lang}/${sortType}/${ascending}?query=${query}`,
      {
        currentPage: currentPage,
        entries: entries,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as DropdownItemsTable];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method adds a new dropdown item.
async function addDropdownItem(
  language: number,
  request: DropdownItemEditRequest
): Promise<[StatusCodes, string]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/add/dropdownitem/${language}`,
      request,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, ""];
      case StatusCodes.CONFLICT.valueOf():
        return [StatusCodes.CONFLICT, axiosError.response.data as any];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method updates an existing dropdown item.
async function editDropdownItem(
  id: number,
  language: number,
  request: DropdownItemEditRequest
): Promise<[StatusCodes, string]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/edit/dropdownitem/${id}/${language}`,
      request,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ""];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as any];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, ""];
    }
  }
}

// This method deletes an existing dropdown item.
async function deleteDropdownItem(id: number): Promise<StatusCodes> {
  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/delete/dropdownitem/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets app update messages.
async function getAppSupportMessages(
  langId: number
): Promise<[StatusCodes, AppSupportMessage[] | null]> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/appsupport/${langId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as AppSupportMessage[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method adds a new app update message.
async function addAppSupportMessage(
  langId: number,
  appMessage: EditAppSupportMessage
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/add/appsupport/${langId}`,
      appMessage,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method updates an existing app update message.
async function updateAppSupportMessage(
  langId: number,
  id: number,
  appMessage: EditAppSupportMessage
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/appsupport/${langId}/${id}`,
      appMessage,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method removes an app update message.
async function removeAppSupportMessage(id: number): Promise<StatusCodes> {
  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/remove/appsupport/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets app update titles.
async function getAppSupportMessageTitles(
  langId: number
): Promise<[StatusCodes, AppSupportMessageTitle[] | null]> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/appsupport/title/${langId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data as AppSupportMessageTitle[]];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method adds a new app update title.
async function addAppSupportMessageTitle(
  langId: number,
  appMessageTitle: EditAppSupportMessageTitle
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/add/appsupport/title/${langId}`,
      appMessageTitle,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method updates an existing app update title.
async function updateAppSupportMessageTitle(
  langId: number,
  id: number,
  appMessageTitle: EditAppSupportMessageTitle
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/appsupport/title/${langId}/${id}`,
      appMessageTitle,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method removes an app update title.
async function removeAppSupportMessageTitle(id: number): Promise<StatusCodes> {
  try {
    const resp = await axios.delete(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/remove/appsupport/title/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      case StatusCodes.CONFLICT.valueOf():
        return StatusCodes.CONFLICT;
      case StatusCodes.NOT_FOUND.valueOf():
        return StatusCodes.NOT_FOUND;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method adds a website view.
async function addWebsiteView(): Promise<StatusCodes> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/visit`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: false,
      }
    );

    if (resp.status === 200) {
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method gets statistics about the website views.
async function getWebsiteViewStatistics(
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<[StatusCodes, ViewStatistics | null]> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.statistics}/visit/${sortType}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [
        StatusCodes.OK,
        Funcs.mapJsonToViewStatistics(JSON.stringify(resp.data)),
      ];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, null];
  } catch (error: any) {
    const axiosError = error as AxiosError;

    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, null];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, null];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, null];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, null];
    }
  }
}

// This method exports the general stats into an excel sheet.
async function exportGeneralStats(): Promise<StatusCodes> {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/generalstats`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the general stats into an excel sheet.
async function exportQueryStats(
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/querystats/${sortType}/${ascending}?query=${query}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the stats about users into an excel sheet.
async function exportUserStats(
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/userstats/${sortType}/${ascending}?query=${query}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the stats about songs into an excel sheet.
async function exportSongStats(
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/songstats/${sortType}/${ascending}?query=${query}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the stats about carts into an excel sheet.
async function exportCartStats(
  query: string,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/cartstats/${sortType}/${ascending}?query=${query}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the stats about dropdowns into an excel sheet.
async function exportDropdownStats(
  query: string,
  language: number,
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/dropdownstats/${language}/${sortType}/${ascending}?query=${query}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports the stats about views into an excel sheet.
async function exportViewStats(
  sortType: number,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/viewstats/${sortType}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

async function exportNameCountStats(type: number, sortByCount: boolean, ascending: boolean) {
  try {
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/nameCount/${type}/${sortByCount == true ? 1 : 2}/${ascending}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

async function exportPerMonthStatsStats(
  type: number,
  sortAfterDate: boolean,
  ascending: boolean,
  startDate: number,
  endDate: number
): Promise<StatusCodes> {
  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.file}/monthlystats/${type}/${sortAfterDate}/${ascending}`,
      {
        startDateUnix: startDate,
        endDateUnix: endDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return StatusCodes.OK;
    }

    return StatusCodes.INTERNAL_SERVER_ERROR;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return StatusCodes.UNAUTHORIZED;
      case StatusCodes.BAD_REQUEST.valueOf():
        return StatusCodes.BAD_REQUEST;
      default:
        return StatusCodes.INTERNAL_SERVER_ERROR;
    }
  }
}

// This method exports an album into an excel sheet.
async function exportKokoIDAlbumAsExcel(
  kokoAlbum: string
): Promise<[StatusCodes, string]> {
  if (kokoAlbum == "") {
    throw new Error("ID can't be empty!");
  }

  try {
    const resp = await axios.post(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/songexport/album`,
      {
        KokoId: kokoAlbum,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      const url = resp.data.link;
      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      return [StatusCodes.OK, ''];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, ''];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, ''];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR,''];
    }
  }
}

async function searchForSimilarTrackWithKokoId(
  kokoId: string
): Promise<[StatusCodes, SimilarMatch[] | string]> {
  try {
    const resp = await axios.get<SimilarMatch[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/similar/id/${encodeURIComponent(kokoId)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getDurationOfVideo(
  url: string
): Promise<number> {
  try {
    const resp = await axios.get<number>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/duration/web/${encodeURIComponent(url)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return resp.data;
    }

    return -1;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return -1;
      case StatusCodes.BAD_REQUEST.valueOf():
        return -1;
      default:
        return -1;
    }
  }
}

async function searchForSimilarTrackWithWeblink(
  link: string,
  startTime: number
): Promise<[StatusCodes, SimilarMatch[] | string]> {
  try {
    const resp = await axios.get<SimilarMatch[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/similar/web/${encodeURIComponent(link)}?start=${startTime}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function searchForSimilarTrackWithFile(
  file: File
): Promise<[StatusCodes, SimilarMatch[] | string]> {
  if (file == null) {
    throw new Error("File can't be empty!");
  }

  const formData = new FormData();

  formData.append("file", file);

  try {
    const resp = await axios.post<SimilarMatch[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/similar/file`,
      formData,
      {
        headers: {
          "Content-Type": "audio/mp3",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function populateSimilarTracks(
  matches: SimilarMatch[]
): Promise<[StatusCodes, SongInfo[] | string]> {
  try {
    const resp = await axios.post<SongInfo[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.song}/populate`,
      matches,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getSynonyms(
  search: string,
  page: number,
  take: number
): Promise<[StatusCodes, SynonymResults | string]> {
  try {
    const resp = await axios.get<SynonymResults>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/synonyms?page=${page}&take=${take}&search=${encodeURIComponent(search)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function addSynonym(
  synonym: Synonym
): Promise<[StatusCodes, string]> {
  try {
    const resp = await axios.post<void>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/synonyms`,
        synonym
      ,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.CONFLICT.valueOf():
        return [StatusCodes.CONFLICT, axiosError.response.data as string];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, axiosError.response.data as string];
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function deleteSynonym(
  id: number
): Promise<[StatusCodes, string]> {
  try {
    const resp = await axios.delete<void>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/synonyms/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.CONFLICT.valueOf():
        return [StatusCodes.CONFLICT, axiosError.response.data as string];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, axiosError.response.data as string];
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function editSynonym(
  id: number,
  synonym: Synonym
): Promise<[StatusCodes, string]> {
  try {
    const resp = await axios.post<void>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/synonyms/${id}`,
        synonym
      ,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, ""];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.CONFLICT.valueOf():
        return [StatusCodes.CONFLICT, axiosError.response.data as string];
      case StatusCodes.NOT_FOUND.valueOf():
        return [StatusCodes.NOT_FOUND, axiosError.response.data as string];
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getKeywords(
  search: string
): Promise<[StatusCodes, IdName[] | string]> {
  try {
    const resp = await axios.get<IdName[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/keywords?search=${encodeURIComponent(search)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getGenres(
  search: string
): Promise<[StatusCodes, IdName[] | string]> {
  try {
    const resp = await axios.get<IdName[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/genres?search=${encodeURIComponent(search)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getSongbodies(
  search: string
): Promise<[StatusCodes, IdName[] | string]> {
  try {
    const resp = await axios.get<IdName[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/songbodies?search=${encodeURIComponent(search)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

async function getArtists(
  search: string
): Promise<[StatusCodes, IdName[] | string]> {
  try {
    const resp = await axios.get<IdName[]>(
      `${process.env.VUE_APP_URL}${apiRouteNames.prefix}${apiRouteNames.maintainance}/artists?search=${encodeURIComponent(search)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (resp.status === 200) {
      return [StatusCodes.OK, resp.data];
    }

    return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
  } catch (error: any) {
    const axiosError = error as AxiosError;
    switch (axiosError.response?.status) {
      case StatusCodes.UNAUTHORIZED.valueOf():
        return [StatusCodes.UNAUTHORIZED, axiosError.response.data as string];
      case StatusCodes.BAD_REQUEST.valueOf():
        return [StatusCodes.BAD_REQUEST, axiosError.response.data as string];
      default:
        return [StatusCodes.INTERNAL_SERVER_ERROR, "Server Error"];
    }
  }
}

//TODO: Aufteilen
//getCart
const RequestFuncs = {
  login,
  logout,
  songSearch,
  directLuceneSearch,
  getSongDetailed,
  getDropDownItems,
  register,
  createPasswordResetLink,
  resetPassword,
  getAccountInfo,
  updateEmail,
  updateAccount,
  verifyEmail,
  verifyUser,
  getCarts,
  getCartsInfo,
  deleteCart,
  deleteCarts,
  updateCart,
  getCartSongs,
  createCart,
  loadCart,
  deleteSongInCart,
  deleteSongsInCart,
  addSongToCart,
  addSongsToCart,
  downloadAllSongsAsMP3,
  downloadAllSongsAsWAV,
  getPublicLink,
  downloadAkmFile,
  searchSongInCart,
  searchUser,
  shareCartWithUsers,
  getShortAccountInfo,
  getExportTargets,
  exportTargetSong,
  getTargetExportSongs,
  getPublicSongs,
  searchPublicSong,
  downloadWavZip,
  downloadMp3Zip,
  copyCart,
  getSortedSongs,
  switchTrackItemsOrder,
  moveTrackItemToPos,
  getLatestCollections,
  getThemeCarts,
  editThemeCart,
  deleteThemeCart,
  deleteThemeCarts,
  getThemecartsCoverFileNames,
  createThemecart,
  uploadCollectionCoverFile,
  searchThemeCarts,
  exportCartAsExcel,
  exportKokoIDsAsExcel,
  getMaintainanceInfo,
  updateMaintainanceInfo,
  getAllMaintainanceInfo,
  cloneThemeCart,
  blockUser,
  unblockUser,
  getSortedUsers,
  deleteUser,
  verifyUserAsAdmin,
  getQueryStatistics,
  getSongStatistics,
  getCartStatistics,
  getUserStatistics,
  getGeneralStatistics,
  getDropdownItemsForTable,
  addDropdownItem,
  editDropdownItem,
  deleteDropdownItem,
  getDropdownStatistics,
  getAppSupportMessages,
  addAppSupportMessage,
  updateAppSupportMessage,
  removeAppSupportMessage,
  getAppSupportMessageTitles,
  addAppSupportMessageTitle,
  updateAppSupportMessageTitle,
  removeAppSupportMessageTitle,
  addWebsiteView,
  getWebsiteViewStatistics,
  exportGeneralStats,
  exportCartStats,
  exportSongStats,
  exportUserStats,
  exportViewStats,
  exportQueryStats,
  exportDropdownStats,
  exportKokoIDAlbumAsExcel,
  getViewsPerMonth,
  getQueriesPerMonth,
  getNewUsersPerMonth,
  getDropdownsPerMonth,
  exportPerMonthStatsStats,
  searchForSimilarTrackWithKokoId,
  searchForSimilarTrackWithFile,
  searchForSimilarTrackWithWeblink,
  populateSimilarTracks,
  getDurationOfVideo,
  getGenreStatistics,
  getKeywordStatistics,
  exportNameCountStats,
  getKeywords,
  getGenres,
  getSongbodies,
  getArtists,
  getSynonyms,
  deleteSynonym,
  editSynonym,
  addSynonym
};

export default RequestFuncs;
