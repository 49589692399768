import { ARTISTTYPES } from "./constants";

// This class represents a route object.
class RouteNav {
  // The name of the route.
  name: string;

  // The route link without the host part.
  route: string;

  constructor(name: string, route: string) {
    this.name = name;
    this.route = route;
  }
}

// This class represents an artist of a song.
class ArtistInfo {
  // The name of the artist.
  name: string;

  // The type of artist.
  // See ARTISTTYPES.
  type: string;

  constructor(name: string, type: string) {
    this.name = name;
    if (ARTISTTYPES.indexOf(type) === -1) {
      throw Error("Artisttype unknown");
    }

    this.type = type;
  }
}

// This class represents information about a song.
class SongInfo {
  // The id of the song in the db.
  id: number;

  // The koko id of the song.
  kokoId: string;

  // The title of the song.
  title: string;

  // The album name that contains the stone.
  album: string;

  // The duration of the song in seconds.
  duration: number;

  // The name of the coverfile.
  coverFilename: string;

  // A boolean indicating wether to hide the addToCart button in the song table.
  isInCart: boolean;

  // This list contains all the artists that composed the song.
  artists: ArtistInfo[];

  // This list contains all keywords of the song.
  keywords: string[];

  // A boolean indicating wether the song is selected or not.
  isSelected: boolean;

  // A list of genres the song belongs to.
  genres: string[];

  // A list of instruments that are played.
  songbodies: string[];

  // The recording year of the song.
  yearOfRecording: number;

  // The industry number of the song.
  industryNumber: string;

  // A boolean indicating wether the song is showing all information.
  isDetailedView: boolean;

  // A boolean indicating wether the song is playing or not.
  isPlaying: boolean;

  similarityScore: number = -1;

  constructor(
    id: number,
    kokoId: string,
    title: string,
    album: string,
    duration: number,
    coverFilename: string,
    isInCart: boolean,
    keywords: string[],
    isSelected = false,
    artists: ArtistInfo[],
    genres: string[],
    songbodies: string[],
    yearOfRecording: number,
    industryNumber: string,
    isDetailedView = false,
    isPlaying = false,
    similarityScore = -1
  ) {
    this.id = id;
    this.kokoId = kokoId;
    this.title = title;
    this.album = album;
    this.duration = duration;
    this.coverFilename = coverFilename;
    this.isInCart = isInCart;
    this.keywords = keywords;
    this.isSelected = isSelected;
    this.artists = artists;
    this.genres = genres;
    this.songbodies = songbodies;
    this.yearOfRecording = yearOfRecording;
    this.industryNumber = industryNumber;
    this.isDetailedView = isDetailedView;
    this.isPlaying = isPlaying;
    this.similarityScore = similarityScore;
  }
}

// This class represents the information about a user created cart.
class CartInfo {
  // The id of the cart in the db.
  shoppingCartId: number;

  // The name of the cart.
  name: string;

  // The description of the cart.
  description: string;

  // The creation date if the cart.
  creationDate: Date;

  // The last loading date of the cart.
  lastLoadingDate: Date;

  // The number of total songs in the cart.
  totalResults: number;

  // A boolean indicating wether the cart is selected or not.
  isSelected: boolean;

  constructor(
    shoppingCartId: number,
    name: string,
    description: string,
    creationDate: Date,
    lastLoadingDate: Date,
    totalResults = 0,
    isSelected = false
  ) {
    this.shoppingCartId = shoppingCartId;
    this.name = name;
    this.description = description;
    this.creationDate = creationDate;
    this.lastLoadingDate = lastLoadingDate;
    this.totalResults = totalResults;
    this.isSelected = isSelected;
  }
}

// This class represents the model for the cart table.
// Because it supports paggination not all carts are shown at the same time.
class CartsResponse {
  // A list of carts that belong to the user.
  carts: CartInfo[];

  // The number of total carts.
  totalResults: number;

  /**
   *
   */
  constructor(carts: CartInfo[], totalResults: number) {
    this.carts = carts;
    this.totalResults = totalResults;
  }
}

// This class represents the item for the dropdown menus in the searchForm component.
class DropdownItem {
  // The id of the item.
  id: number;

  // The localized text of the item.
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

// This class is used to display short inforamtion about a user.
// This is used whenever you search for a user.
class UserResult {
  // The idmof the user in the db.
  id: number;

  // The fullname of the user.
  name: string;

  // The email of the user.
  email: string;

  constructor(id: number, name: string, email: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }
}

// This class is used to export a song to a target.
class TargetExportRequest {
  // The first name of the user that exports the cart.
  firstName: string;

  // The last name of the user.
  lastName: string;

  // The email of the user.
  email: string;

  // The production number of the export.
  productionNumber: string;

  // The target of the export.
  uncPath: string;

  // The id of the song that gets exported.
  songId: number;

  constructor(
    name: string,
    surname: string,
    email: string,
    productionNumber: string,
    uncPath: string,
    songId: number
  ) {
    this.firstName = name;
    this.lastName = surname;
    this.email = email;
    this.productionNumber = productionNumber;
    this.uncPath = uncPath;
    this.songId = songId;
  }
}

// This class is used to represent a possible target for the export.
class TargetExportTargetItem {
  // The description of the target.
  description: string;

  // The path of the target.
  uncPath: string;

  constructor(description: string, uncPath: string) {
    this.description = description;
    this.uncPath = uncPath;
  }
}

// This class contains all informationa about the target export request.
// This object is mainly used in the vuex storage.
class TargetExportProp {
  // The name of the user that wants to export the cart.
  firstName: string;

  // The last name of the user that wants to export the cart.
  lastName: string;

  // The email of the user.
  email: string;

  // The production number of the export.
  productionNumber: string;

  // The target to export to.
  uncPath: string;

  // The name of the cart that will be exported.
  cartName: string;

  // The id of the cart that will be exported.
  cartId: number;

  constructor(
    name: string,
    surname: string,
    email: string,
    productionNumber: string,
    uncPath: string,
    cartName: string,
    cartId: number
  ) {
    this.firstName = name;
    this.lastName = surname;
    this.email = email;
    this.productionNumber = productionNumber;
    this.uncPath = uncPath;
    this.cartName = cartName;
    this.cartId = cartId;
  }
}

// This class holds information about a song that will be exported.
class TargetExportSongInfo {
  // The kokoid of the song that will be exported.
  kokoId: string;

  // The id of the song that will be exported.
  songId: number;

  // The title of the song that will be exported.
  title: string;

  // The duration of the song that will be exported.
  length: string;

  // A boolean indicating wether the song has been exported.
  hasBeenExported: boolean;

  /**
   *
   */
  constructor(
    kokoId: string,
    songId: number,
    title: string,
    length: string,
    hasBeenExported = false
  ) {
    this.kokoId = kokoId;
    this.songId = songId;
    this.title = title;
    this.length = length;
    this.hasBeenExported = hasBeenExported;
  }
}

// This class holds user account information.
class AccountInfo {
  // The title of the user.
  title: string;

  // The first name of the user.
  name: string;

  // The last name of the user.
  surname: string;

  // The company of the user.
  company: string;

  // The job of the user.
  job: string;

  // The cel number of the user.
  cel: string;

  // The cel prefix of the user.
  celPrefix: string;

  constructor(
    title: string,
    name: string,
    surname: string,
    company: string,
    job: string,
    cel: string,
    celPrefix: string
  ) {
    this.title = title;
    this.name = name;
    this.surname = surname;
    this.company = company;
    this.job = job;
    this.cel = cel;
    this.celPrefix = celPrefix;
  }
}

// This class holds a short version of user account information.
class ShortAccountInfo {
  // The first name of the user.
  name: string;

  // The last name of the user.
  surname: string;

  // The email of the user.
  email: string;

  constructor(name: string, surname: string, email: string) {
    this.name = name;
    this.surname = surname;
    this.email = email;
  }
}

// This class holds search parameters hat are used by the search mask.
class SongSearchForm {
  // The current page of the search.
  currentPage: number;

  // The number of enmtries to retrieve.
  numberOfSongs: number;

  // The choosen music style.
  musicStyle: number;

  // The choosen emotion.
  emotion: number;

  // The choosen music occasion.
  musicOccasion: number;

  // The choosen instrument.
  instrument: number;

  // The minimum duration of the song in minutes.
  musicMinTimeM: number;

  // The minimum duration of the song in seconds.
  musicMinTimeS: number;

  // The maximum duration of the song in minutes.
  musicMaxTimeM: number;

  // The maximum duration of the song in seconds.
  musicMaxTimeS: number;

  // The search query of the song.
  query: string;

  // The id of the current cart
  cartId: number;

  constructor(
    currentPage = 1,
    numberOfSongs = 15,
    musicStyle = -1,
    emotion = -1,
    musicOccasion = -1,
    instrument = -1,
    musicMinTimeM = -1,
    musicMinTimeS = -1,
    musicMaxTimeM = -1,
    musicMaxTimeS = -1,
    query = "",
    cartId = -1
  ) {
    this.currentPage = currentPage;
    this.numberOfSongs = numberOfSongs;
    this.emotion = emotion;
    this.instrument = instrument;
    this.musicStyle = musicStyle;
    this.musicOccasion = musicOccasion;
    this.musicMinTimeM = musicMinTimeM;
    this.musicMinTimeS = musicMinTimeS;
    this.musicMaxTimeM = musicMaxTimeM;
    this.musicMaxTimeS = musicMaxTimeS;
    this.query = query;
    this.cartId = cartId;
  }
}

// This class represents a lucene search request only used by mv employees.
// This request is unsanitized.
class LuceneSearchRequest {
  // The search string.
  query: string;

  // The current page of the search.
  currentpage: number;

  // The number of entries to retrieve.
  numberOfSongs: number;

  // The id of the currently active cart.
  cartId: number;

  /**
   *
   */
  constructor(
    query: string,
    currentpage: number,
    numberOfSongs: number,
    cartId = -1
  ) {
    this.query = query;
    this.currentpage = currentpage;
    this.numberOfSongs = numberOfSongs;
    this.cartId = cartId;
  }
}

// This class is used to register a new account.
class CreateAccountRequest extends AccountInfo {
  // The email of the user.
  email: string;

  // The password of the user.
  password: string;
  /**
   *
   */
  constructor(
    title: string,
    name: string,
    surname: string,
    company: string,
    job: string,
    cel: string,
    celPrefix: string,
    email: string,
    password: string
  ) {
    super(title, name, surname, company, job, cel, celPrefix);
    this.email = email;
    this.password = password;
  }
}

// This class is used to encapsulate song responses from the api.
// Used in the songTable component.
class SongSearchResponse {
  // A list of songs that show in the view.
  searchResult: SongInfo[];

  // The total amount of songs in the cart/search.
  totalResults: number;

  /**
   *
   */
  constructor(searchResult: SongInfo[], totalResults: number) {
    this.searchResult = searchResult;
    this.totalResults = totalResults;
  }
}

// This class is used to encapsulate song responses from the api.
// Only used when dealing with a public cart.
// Used in the songTable component.
class PublicCartResponse {
  // A list of songs that show in the view.
  searchResult: SongInfo[];

  // The total amount of songs in the cart/search.
  totalResults: number;

  // The name of the public cart.
  cartName: string;

  /**
   *
   */
  constructor(searchResult: SongInfo[], totalResults: number, cartName: string) {
    this.searchResult = searchResult;
    this.totalResults = totalResults;
    this.cartName = cartName;
  }
}

// This class represents a verification response from the api.
class VerifyResponse {
  // The email of the verified user.
  email: string;

  // The verification mode.
  //Either verification or change
  mode: string;
  /**
   *
   */
  constructor(email: string, mode: string) {
    this.email = email;
    this.mode = mode;
  }
}

// This class hold information about theme collections.
// This is used in the news popup.
class Themecart {
  // The id of the collection
  id: number;

  // The title of the collection.
  title: string;

  // The description of the collection.
  description: string;

  // The guid of the public cart that belongs to the collecion.
  publicGuid: string;

  /**
   *
   */
  constructor(
    id: number,
    title: string,
    description: string,
    publicGuid: string
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.publicGuid = publicGuid;
  }
}

// This class is used display a themecart in the news modal.
class ExtendedThemecart extends Themecart {
  // The order number.
  // This is used in the newsmodal to decide the color of the description box.
  order: number;

  /**
   *
   */
  constructor(
    id: number,
    title: string,
    description: string,
    publicGuid: string,
    order: number
  ) {
    super(id, title, description, publicGuid);
    this.order = order;
  }
}

// This class holds information about an array of theme collections.
// Used by the admin view for the table.
class ThemecartsAdminInfo {
  // Gets or sets an array of theme collection objects.
  carts: ThemecartAdminInfo[];

  // Gets or sets the number of theme collection entries.
  results: number;

  /**
   *
   */
  constructor(carts: ThemecartAdminInfo[], results: number) {
    this.carts = carts;
    this.results = results;
  }
}

// This class is used to edit a theme collection.
class ThemecartEditRequest {
  // The id of the collection
  id: number;

  // The title of the collection.
  title: string;

  // The description of the collection.
  description: string;

  // The name of the cart used.
  cartId: number;

  // Gets or sets a boolean indicating wether to show the theme collection in the news modal.
  showThemeCart: boolean;

  // Gets or sets the name of the cover file.
  coverFileName: string;

  constructor(
    id: number,
    title: string,
    description: string,
    coverFileName: string,
    cartId: number,
    show: boolean
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.coverFileName = coverFileName;
    this.cartId = cartId;
    this.showThemeCart = show;
  }
}

// This class hold information about theme collections.
// This is used for the admin view.
class ThemecartAdminInfo {
  // The id of the collection
  id: number;

  // The title of the collection.
  title: string;

  // The description of the collection.
  description: string;

  // The guid of the public cart that belongs to the collecion.
  publicGuid: string;

  // The name of the cart used.
  cartId: number;

  cartName: string;

  // Gets or sets a boolean indicating wether to show the theme collection in the news modal.
  show: boolean;

  // Gets or sets a boolean indicating wether the item is selected or not.
  isSelected = false;

  // Gets or sets the name of the cover file.
  coverFileName: string;

  /**
   *
   */
  constructor(
    id: number,
    title: string,
    description: string,
    coverFileName: string,
    publicGuid: string,
    cartId: number,
    show: boolean,
    isSelected = false,
    cartName: string = ''
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.coverFileName = coverFileName;
    this.cartId = cartId;
    this.show = show;
    this.isSelected = isSelected;
    this.publicGuid = publicGuid;
    this.cartName = cartName;
  }
}

// This class is used to create a new theme collection.
class ThemecartCreateRequest {
  // The title of the collection.
  title: string;

  // The description of the collection.
  description: string;

  // The name of the cart used.
  cartId: number;

  showThemeCart: boolean;

  coverFileName: string;

  constructor(
    title: string,
    description: string,
    coverFileName: string,
    cartId: number,
    show: boolean
  ) {
    this.title = title;
    this.description = description;
    this.coverFileName = coverFileName;
    this.cartId = cartId;
    this.showThemeCart = show;
  }
}

// This class is used for the cartdropdown when creating a new theme colletion.
class DropDownCartItem {
  // Gets or sets the text of the selector item.
  text: string;

  // Gets or sets the value of the selector item.
  value: number | null;

  /**
   *
   */
  constructor(text: string, value: number | null) {
    this.text = text;
    this.value = value;
  }
}

// This class is used for the coverfile selector when creating a new theme colletion.
class DropDownCoverItem {
  // Gets or sets the name of the cover file.
  text: string;

  // Gets or sets the value of the cover file.
  value: string;

  /**
   *
   */
  constructor(text: string, value: string) {
    this.text = text;
    this.value = value;
  }
}

// This class holds information about a maintainance period.
// This is displayed in the news modal.
class MaintainancePeriod {
  // Gets or sets the description of the period.
  description: string;

  // Gets or sets the start date of the period.
  startDate: Date;

  // Gets or sets the end date of the period.
  endDate: Date;

  constructor(description: string, startDate: Date, endDate: Date) {
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

// This class is used when editing a maintainance period.
// Used in the admin view.
class MaintainancePeriodEdit extends MaintainancePeriod {
  // Gets or sets the id of the perdiod entry.
  id: number;

  constructor(id: number, description: string, startDate: Date, endDate: Date) {
    super(description, startDate, endDate);
    this.id = id;
  }
}

// This class holds searchquery statistic.
class QueryResult {
  // Gets or sets the id of the entry.
  id: number;

  // Gets or sets the searchquery.
  query: string;

  // Gets or sets the numbers of results of the searchquery.
  results: number;

  // Gets or sets the number of times the searchquery was searched.
  timesCalled: number;

  emotion: string;

  musicOccasion: string;

  instrument: string;

  musicStyle: string;

  minDurationInSeconds: string;

  maxDurationInSeconds: string;

  dateAsString: string;
  /**
   *
   */
  constructor(
    id: number,
    query: string,
    results: number,
    timesCalled: number,
    emotion: string,
    musicOccasion: string,
    instrument: string,
    musicStyle: string,
    minDurationInSeconds: string,
    maxDurationInSeconds: string,
    dateAsString: string
  ) {
    this.id = id;
    this.query = query;
    this.results = results;
    this.timesCalled = timesCalled;
    this.emotion = emotion;
    this.musicOccasion = musicOccasion;
    this.instrument = instrument;
    this.musicStyle = musicStyle;
    this.minDurationInSeconds = minDurationInSeconds;
    this.maxDurationInSeconds = maxDurationInSeconds;
    this.dateAsString = dateAsString;
  }
}

// This class contains an array of searchquery entries and the total amount of searchresults.
class QueryResults {
  // Gets or sets an array of query statistic entries.
  queryResults: QueryResult[];

  // Gets or sets the total amount of searchresults.
  results: number;

  /**
   *
   */
  constructor(queryResults: QueryResult[], results: number) {
    this.queryResults = queryResults;
    this.results = results;
  }
}

class NameCountStatistics{
  statistics: NameCountStatistic[];
  results: number;

  /**
   *
   */
  constructor(statistics: NameCountStatistic[],
    results: number) {
    this.statistics = statistics;
    this.results = results;
    
  }

}

class NameCountStatistic{
  name: string;
  count: number;

  /**
   *
   */
  constructor(name: string,
    count: number) {
    this.name = name;
    this.count = count;
    
  }
}

// This class contains general statistics about the app.
class GeneralStatistic {
  // Gets or sets the total amount of songs.
  totalSongs: number;

  // Gets or sets the total amount of artists.
  totalArtists: number;

  // Gets or sets the total amount of registered users.
  totalRegisteredUsers: number;

  // Gets or sets the total amount of carts.
  totalCarts: number;

  // Gets or sets the average number of carts per user.
  averageCarts: number;

  // Gets or sets the average songs per cart.
  averageSongsPerCarts: number;

  // Gets or sets the number of users with no carts.
  userWithNoCarts: number;

  /**
   *
   */
  constructor(
    totalSongs: number,
    totalArtists: number,
    totalRegisteredUsers: number,
    totalCarts: number,
    averageCarts: number,
    averageSongsPerCarts: number,
    userWithNoCarts: number
  ) {
    this.totalSongs = totalSongs;
    this.totalArtists = totalArtists;
    this.totalRegisteredUsers = totalRegisteredUsers;
    this.totalCarts = totalCarts;
    this.averageCarts = averageCarts;
    this.averageSongsPerCarts = averageSongsPerCarts;
    this.userWithNoCarts = userWithNoCarts;
  }
}

// This class contains a statistic about a registered user.
class UserStatistic {
  // Gets or sets the id of the user.
  userId: number;

  // Gets or sets the registration date from the user.
  registrationDate: Date;

  // Gets or sets the title of the user.
  title: string;

  // Gets or sets the first name of the user.
  firstName: string;

  // Gets or sets the last name of the user.
  lastName: string;

  // Gets or sets the profession of the user.
  profession: string;

  // Gets or sets the company of the user.
  company: string;

  // Gets or sets the email of the user.
  email: string;

  // Gets or sets the cel number of the user.
  phoneNumber: string;

  // Gets or sets the account state of the user.
  accountState: string;

  // Gets or sets the number of carts the user owns.
  numberOfCarts: number;

  constructor(
    userId: number,
    registrationDate: Date,
    title: string,
    firstName: string,
    lastName: string,
    profession: string,
    company: string,
    email: string,
    phoneNumber: string,
    accountState: string,
    numberOfCarts: number
  ) {
    this.userId = userId;
    this.registrationDate = registrationDate;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.profession = profession;
    this.company = company;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.accountState = accountState;
    this.numberOfCarts = numberOfCarts;
  }
}

// This class contains an array of statistics about users and the total amount of user statistics.
class UserStatistics {
  // Gets or sets an array of user statisitcs.
  statistics: UserStatistic[];

  // Gets or sets the total amount of user statistics.
  results: number;

  /**
   *
   */
  constructor(statistics: UserStatistic[], results: number) {
    this.statistics = statistics;
    this.results = results;
  }
}

// This class contains a statistic about a track.
class SongStatistic {
  // Gets or sets the kokoid of the track.
  kokoId: string;

  // Gets or sets the title of the track.
  title: string;

  // Gets or sets the album of the track.
  album: string;

  // Gets or sets the duration of the track.
  duration: number;

  // Gets or sets the artists of the track.
  artists: ArtistInfo[];

  // Gets or sets the year of recording.
  yearOfRecording: number;

  // Gets or sets the total number of times the track was played.
  timesPlayed: number;

  // Gets or sets the total number of times the track was downloaded.
  timesDownloaded: number;

  /**
   *
   */
  constructor(
    kokoId: string,
    title: string,
    album: string,
    duration: number,
    artists: ArtistInfo[],
    yearOfRecording: number,
    timesPlayed: number,
    timesDownloaded: number
  ) {
    this.kokoId = kokoId;
    this.title = title;
    this.album = album;
    this.duration = duration;
    this.artists = artists;
    this.yearOfRecording = yearOfRecording;
    this.timesDownloaded = timesDownloaded;
    this.timesPlayed = timesPlayed;
  }
}

// This class contains an array of statistics about tracks and the total amount of track statistics.
class SongStatistics {
  // Gets or sets an array of track statistics.
  statistics: SongStatistic[];

  // Gets or sets the total number track statistics.
  results: number;

  /**
   *
   */
  constructor(statistics: SongStatistic[], results: number) {
    this.statistics = statistics;
    this.results = results;
  }
}

// This class contains a statistic about a cart.
class CartStatistic {
  // Gets or sets the title of the cart.
  title: string;

  // Gets or sets the number of songs in the cart.
  songCount: number;

  // Gets or sets the number of times the cart was called.
  timesCalled: number;

  // Gets or sets the guid of the cart.
  guid: string;

  /**
   *
   */
  constructor(
    title: string,
    songCount: number,
    timesCalled: number,
    guid: string
  ) {
    this.title = title;
    this.songCount = songCount;
    this.timesCalled = timesCalled;
    this.guid = guid;
  }
}

// This class contains an array of statistics about carts and the total amount of cart statistics.
class CartStatistics {
  // Gets or sets an array of statistics about carts.
  statistics: CartStatistic[];

  // Gets or sets the total amount of cart statistics.
  results: number;

  /**
   *
   */
  constructor(statistics: CartStatistic[], results: number) {
    this.statistics = statistics;
    this.results = results;
  }
}

// This class contains a statistic about a dropdown item.
class DropdownStatistic {
  // Gets or sets the unique name of the dropdown item.
  resourceSheetKey: string;

  // Gets or sets the lucene query of the dropdown item.
  lucenePattern: string;

  // Gets or sets the dropdown category of the item.
  category: string;

  // Gets or sets the number of times the dropdown was called.
  timesCalled: number;

  // Gets or sets the localized string of the dropdown.
  localization: string;

  /**
   *
   */
  constructor(
    resourceSheetKey: string,
    lucenePattern: string,
    category: string,
    timesCalled: number,
    localization: string
  ) {
    this.resourceSheetKey = resourceSheetKey;
    this.lucenePattern = lucenePattern;
    this.category = category;
    this.timesCalled = timesCalled;
    this.localization = localization;
  }
}

// This class contains an array of statistics about dropdown and the total amount of dropdown statistics.
class DropdownStatistics {
  // Gets or sets an array of statistics about dropdowns.
  statistics: DropdownStatistic[];

  // Gets or sets the total amount of dropdown statistics.
  results: number;

  /**
   *
   */
  constructor(statistics: DropdownStatistic[], results: number) {
    this.statistics = statistics;
    this.results = results;
  }
}

// This class is used by the diagram element in the statistics.
class BarDiagramItem {
  x: string;
  y: number;

  /**
   *
   */
  constructor(x: string, y: number) {
    this.x = x;
    this.y = y;
  }
}

// This class contains information about a registered user.
// This class is used when managing users.
class UserInfo {
  // Gets or sets the id of the user.
  userId: number;

  // Gets or sets the registration date of the user.
  registrationDate: Date;

  // Gets or sets the title of the user.
  title: string;

  // Gets or sets the name of the user.
  firstName: string;

  // Gets or sets the last name of the user.
  lastName: string;

  // Gets or sets the profession of the user.
  profession: string;

  // Gets or sets the company of the user.
  company: string;

  // Gets or sets the email of the user.
  email: string;

  // Gets or sets the cel number of the user.
  phoneNumber: string;

  // Gets or sets the account state of the user.
  accountState: string;

  /**
   *
   */
  constructor(
    userId: number,
    registrationDate: Date,
    title: string,
    firstName: string,
    lastName: string,
    profession: string,
    company: string,
    email: string,
    phoneNumber: string,
    accountState: string
  ) {
    this.userId = userId;
    this.registrationDate = registrationDate;
    this.title = title;
    this.firstName = firstName;
    this.lastName = lastName;
    this.profession = profession;
    this.company = company;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.accountState = accountState;
  }
}

// This class contains an array of user objects and the total amount of users.
class UserSearchInfo {
  // Gets or sets the array of user objects.
  users: UserInfo[];

  // Gets or sets the total amount of users.
  results: number;

  /**
   *
   */
  constructor(users: UserInfo[], results: number) {
    this.users = users;
    this.results = results;
  }
}

// This class contains the information stored in a cookie.
class CredentialCookie {
  // Gets or sets a boolean indicating wether the user is a mv employee.
  isMVuser: boolean;

  // Gets or sets the name of the user.
  userName: string;

  /**
   *
   */
  constructor(isMVuser: boolean, userName: string) {
    this.isMVuser = isMVuser;
    this.userName = userName;
  }
}

// This class contains information about a dropdown item.
class DropdownItemTable {
  // Gets or sets the id of the dropdown item.
  id: number;

  // Gets or sets the unique name of the item.
  resourceSheetKey: string;

  // Gets or sets lucene pattern of the dropdown item.
  lucenePattern: string;

  // Gets or sets the dropdown category id.
  categoryId: number;

  // Gets or sets the localized string of the dropdown item.
  localization: string;

  /**
   *
   */
  constructor(
    id: number,
    resourceSheetKey: string,
    lucenePattern: string,
    categoryId: number,
    localization: string
  ) {
    this.id = id;
    this.resourceSheetKey = resourceSheetKey;
    this.lucenePattern = lucenePattern;
    this.categoryId = categoryId;
    this.localization = localization;
  }
}

// This class contains information about a collection of dropdown items.
class DropdownItemsTable {
  // Gets or sets an array of dropdown items.
  parameters: DropdownItemTable[];

  // Gets or sets the total amount of dropdown items.
  results: number;

  /**
   *
   */
  constructor(parameters: DropdownItemTable[], results: number) {
    this.parameters = parameters;
    this.results = results;
  }
}

// This class is used to edit or create a new dropdown item.
class DropdownItemEditRequest {
  // Gets or sets the unique name of the dropdown item.
  resourceSheetKey: string;

  // Gets or sets the lucene pattern of the dropdown.
  lucenePattern: string;

  // Gets or sets category dropdown id of the dropdown item.
  categoryId: number;

  // Gets or sets the localized name of the dropdown.
  localization: string;

  /**
   *
   */
  constructor(
    resourceSheetKey: string,
    lucenePattern: string,
    categoryId: number,
    localization: string
  ) {
    this.resourceSheetKey = resourceSheetKey;
    this.lucenePattern = lucenePattern;
    this.categoryId = categoryId;
    this.localization = localization;
  }
}

// This class is used for exporting a range of tracks to an excel file in the adminexcelview.
// All songs inbetween the two ids gets exported to an excel sheet.
class KokoIdRange {
  // Gets or sets the kokoid of the first song to export.
  startKokoId: string;

  // Gets or sets the kokoid of the last song to export.
  endKokoId: string;

  /**
   *
   */
  constructor(startKokoId: string, endKokoId: string) {
    this.startKokoId = startKokoId;
    this.endKokoId = endKokoId;
  }
}

// This class contains information about an app update message.
class AppSupportMessage {
  // Gets or sets the id of the app update message.
  id: number;

  // Gets or sets localized string of the message.
  description: string;

  // Gets or sets the id of the associated title.
  appTitleId: number;

  /**
   *
   */
  constructor(id: number, description: string, appTitleId: number) {
    this.id = id;
    this.appTitleId = appTitleId;
    this.description = description;
  }
}

// This class is used to display a app messages in the table.
class AppSupportMessageUI extends AppSupportMessage {
  // Gets or sets the associated title of the message.
  title: string;
  /**
   *
   */
  constructor(
    id: number,
    appTitleId: number,
    description: string,
    title: string
  ) {
    super(id, description, appTitleId);
    this.title = title;
  }
}

// This class is used to edit or create a new app update message.
class EditAppSupportMessage {
  // Gets or sets the localized message.
  description: string;

  // Gets or sets the associated app title id.
  appTitleId: number;

  /**
   *
   */
  constructor(appTitleId: number, description: string) {
    this.description = description;
    this.appTitleId = appTitleId;
  }
}

// This class is used to display a title for app messages.
class AppSupportMessageTitle {
  // Gets or sets the localized message.
  id: number;

  // Gets or sets the associated app version id.
  title: string;

  constructor(title: string, id: number) {
    this.id = id;
    this.title = title;
  }
}

// This class is used to to display messages in the news modal.
class AppSupportMessageTitleUI extends AppSupportMessageTitle {
  // Gets or sets the messages to display.
  messages: AppSupportMessage[];

  /**
   *
   */
  constructor(title: string, id: number, messages: AppSupportMessage[]) {
    super(title, id);
    this.messages = messages;
  }
}

// This message is used to edit a title for app versions.
class EditAppSupportMessageTitle {
  // Gets or sets the associated app version id.
  title: string;

  constructor(title: string) {
    this.title = title;
  }
}

// This class contains a statistic about a website view entry.
class ViewStatistic {
  // Gets or sets the number of times the website was called.
  timesCalled: number;

  // Gets or sets the date of the entry.
  date: Date;

  /**
   *
   */
  constructor(timesCalled: number, date: Date) {
    this.timesCalled = timesCalled;
    this.date = date;
  }
}

// This class contains an array of statistics about website views and the total amount of website view statistics.
class ViewStatistics {
  // Gets or sets an array of view entries.
  statistics: ViewStatistic[];

  // Gets or sets the total number of entries.
  totalResults: number;

  // Gets or sets the total number of times a website was called.
  totalTimesCalled: number;

  /**
   *
   */
  constructor(
    statistics: ViewStatistic[],
    totalResults: number,
    totalTimesCalled: number
  ) {
    this.statistics = statistics;
    this.totalResults = totalResults;
    this.totalTimesCalled = totalTimesCalled;
  }
}

// This class is used to store information about the news cookie.
class NewsCookie {
  // Gets or sets the version of the cookie the user last saw.
  version: string;

  // Gets or sets the expiration date of the cookie.
  expirationDate: Date;

  /**
   *
   */
  constructor(version: string, expirationDate: Date) {
    this.version = version;
    this.expirationDate = expirationDate;
  }
}

class SimilarMatch{
  outer_id: string;
  score: number;

  constructor(outer_id: string, score: number) {
    this.outer_id = outer_id;
    this.score = score;
  }
}

class SynonymResults{
  synonyms: Synonym[];
  results: number;

  /**
   *
   */
  constructor(synonyms: Synonym[],
    results: number) {
    this.results = results;
    this.synonyms = synonyms;
    
  }
}

class IdName{
  id: number;
  name: string;

  /**
   *
   */
  constructor(id: number,
    name: string) {
    this.id = id;
    this.name = name;
    
  }
}

class Synonym{
  id: number;
  name: string;
  artists: IdName[];
  keywords: IdName[];
  genres: IdName[];
  songBodies: IdName[];

  /**
   *
   */
  constructor(id: number,
    name: string,
    artists: IdName[],
    keywords: IdName[],
    genres: IdName[],
    songbodies: IdName[]) {
    this.id = id;
    this.name = name;
    this.artists = artists;
    this.keywords = keywords;
    this.genres = genres;
    this.songBodies = songbodies;
  }

}

export {
  RouteNav,
  ArtistInfo,
  SongInfo,
  CartInfo,
  DropdownItem,
  UserResult,
  TargetExportRequest,
  AccountInfo,
  SongSearchForm,
  SongSearchResponse,
  LuceneSearchRequest,
  CreateAccountRequest,
  VerifyResponse,
  CartsResponse,
  TargetExportTargetItem,
  TargetExportProp,
  ShortAccountInfo,
  TargetExportSongInfo,
  PublicCartResponse,
  Themecart,
  ExtendedThemecart,
  ThemecartAdminInfo,
  DropDownCartItem,
  DropDownCoverItem,
  ThemecartsAdminInfo,
  ThemecartEditRequest,
  ThemecartCreateRequest,
  MaintainancePeriod,
  MaintainancePeriodEdit,
  QueryResult,
  QueryResults,
  BarDiagramItem,
  UserInfo,
  UserSearchInfo,
  CartStatistic,
  CartStatistics,
  UserStatistic,
  UserStatistics,
  SongStatistic,
  SongStatistics,
  GeneralStatistic,
  CredentialCookie,
  DropdownItemTable,
  DropdownItemsTable,
  DropdownItemEditRequest,
  DropdownStatistics,
  DropdownStatistic,
  KokoIdRange,
  AppSupportMessage,
  EditAppSupportMessage,
  AppSupportMessageTitle,
  EditAppSupportMessageTitle,
  ViewStatistic,
  ViewStatistics,
  AppSupportMessageUI,
  NewsCookie,
  AppSupportMessageTitleUI,
  SimilarMatch,
  NameCountStatistic,
  NameCountStatistics,
  IdName,
  Synonym, 
  SynonymResults
};
