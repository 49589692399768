import { routeNames, CREDENTIAL_COOKIE_IDENTIFIER_KEY } from "@/util/constants";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Cookies from "js-cookie";
import { CredentialCookie } from "@/util/classes";
import store from "../store/index";

// Register the router in the app.
Vue.use(VueRouter);

// This constant holds all possible routes of the frontend.
const routes: Array<RouteConfig> = [
  {
    path: "/shoppingcart/index",
    name: routeNames.cartIndex,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
    props: (route) => ({ id: route.query.id }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shoppingcart/management",
    name: routeNames.cartsManagement,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Carts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shoppingcart/public/:guid",
    name: routeNames.publicCart,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PublicCart.vue"),
    props: true,
  },
  {
    path: "/shoppingcart/targetexport",
    name: routeNames.targetExport,
    component: () => import("../views/TargetExport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/song/lucenesearch",
    name: routeNames.luceneSearch,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MusicSearchLucene.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/song/index",
    name: routeNames.musicIndex,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MusicSearch.vue"),
    props: (route) => ({ lucenequery: route.query.lucenequery, simKokoId: route.query.simKokoId }),
  },
  {
    path: "/song/index/popup",
    name: routeNames.homeWithOpenPopup,
    redirect: (to) => {
      store.dispatch("setThemePopupBool", true);
      return { path: "/song/index" };
    },
  },
  // {
  //   path: "/song/similarity-search",
  //   name: routeNames.similaritySearch,
  //   component: () => import("../views/AdminSimilaritySearchView.vue"),

  // },
  {
    path: "/identity/login",
    name: routeNames.login,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/identity/logout",
    name: routeNames.logout,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/identity/register",
    name: routeNames.register,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue"),
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: "/identity/resetpassword/:id/:guid",
    name: routeNames.resetPassword,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue"),
    props: true,
    meta: {
      requiresAnon: true,
    },
  },
  {
    path: "/identity/resetpassword",
    name: routeNames.resetPasswordRequest,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPasswordReq.vue"),
  },
  {
    path: "/account/edit",
    name: routeNames.editAccount,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Account.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/verify/:id/:guid",
    name: routeNames.verifyAccount,
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Verify.vue"),
  },
  {
    path: "/account/emailverify/:id/:guid",
    name: routeNames.verifyEmail,
    props: true,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Verify.vue"),
  },
  {
    path: "/error/general",
    name: routeNames.generalError,
    props: { errorCode: "0" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/error/500",
    name: routeNames.internalError,
    props: { errorCode: "1" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/error/404",
    name: routeNames.notFoundError,
    props: { errorCode: "3" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/error/notimplemented",
    name: routeNames.notImplementedError,
    props: { errorCode: "4" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/error/emailverify",
    name: routeNames.emailVerifyError,
    props: { errorCode: "5" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/error/verify",
    name: routeNames.userVerifyError,
    props: { errorCode: "5" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Error.vue"),
  },
  {
    path: "/info/emailupdate",
    name: routeNames.emailUpdateInfo,
    props: { code: "1" },
    component: () => import("../views/GenericInfo.vue"),
  },
  {
    path: "/info/emailverify",
    name: routeNames.emailVerifyInfo,
    props: { code: "2" },
    component: () => import("../views/GenericInfo.vue"),
  },
  {
    path: "/info/emailchange",
    name: routeNames.emailChangeInfo,
    props: { code: "3" },
    component: () => import("../views/GenericInfo.vue"),
  },
  {
    path: "/info/verify",
    name: routeNames.verifyUserInfo,
    props: { code: "4" },
    component: () => import("../views/GenericInfo.vue"),
  },
  {
    path: "/admin/collections",
    name: routeNames.adminView,
    component: () => import("../views/AdminThemeCartsView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/export",
    name: routeNames.adminExportView,
    component: () => import("../views/AdminExcelView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/maintainance",
    name: routeNames.adminMaintainanceView,
    component: () => import("../views/AdminMaintainanceView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/statistics",
    name: routeNames.adminStatisticsView,
    component: () => import("../views/AdminStatisticView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/users",
    name: routeNames.adminUsersView,
    component: () => import("../views/AdminUserView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/dropdowns",
    name: routeNames.adminDropdownView,
    component: () => import("../views/AdminDefaultDropdownView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  {
    path: "/admin/synonyms",
    name: routeNames.adminSynonymView,
    component: () => import("../views/AdminSynonymView.vue"),
    meta: {
      requiresMvUser: true,
    },
  },
  // {
  //   path: "/admin/similarity-search",
  //   name: routeNames.similaritySearch,
  //   component: () => import("../views/AdminSimilaritySearchView.vue"),
  //   meta: {
  //     requiresMvUser: true,
  //   },
  // },
  {
    path: "/",
    name: routeNames.home,
    redirect: "/song/index",
  },
  {
    path: "*",
    redirect: "/error/404",
  },
];

const router = new VueRouter({
  mode: "history", // History mode vs hash mode
  routes,
});

// This method checks if the user is logged in, before routing.
const requiresAuthGuard = (to: Route, from: Route, next: any): boolean => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLoggedIn =
      Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY) != undefined;
    if (!isLoggedIn) {
      next({
        name: routeNames.login,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

// This method checks if the user is a mv employee.
const requiresMvUserGuard = (to: Route, from: Route, next: any): boolean => {
  if (to.matched.some((record) => record.meta.requiresMvUser)) {
    const credObj = Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY);
    if (credObj == undefined) {
      next({
        name: routeNames.login,
        query: { redirect: to.fullPath },
      });
    }

    const credObj2 = JSON.parse(
      Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY)!
    ) as CredentialCookie;
    if (!credObj2.isMVuser) {
      next({
        name: routeNames.login,
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

// This method checks if the user really is unauthenticated.
const requiresAnonymous = (to: Route, from: Route, next: any): boolean => {
  if (to.matched.some((record) => record.meta.requiresAnon)) {
    const credObj = Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY);
    if (credObj == undefined) {
      next();
    } else {
      next({
        name: routeNames.login,
      });
    }

    return true;
  }
  return false;
};

// This method contains all the guards.
router.beforeEach(async (to, from, next) => {
  if (requiresAuthGuard(to, from, next)) {
    return;
  }
  if (requiresMvUserGuard(to, from, next)) {
    return;
  }
  if (requiresAnonymous(to, from, next)) {
    return;
  }
  next(); // make sure to always call next()!
});

export default router;
